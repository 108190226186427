
:root{
    --container-width: 230px;
    --main-color:#e52579;
    --second-color: #252c58;
    --third-color: #252c58;
    --widget-bg: #e8eafcad ;
}
.custom-btn-group, .menu-btn-group {
    display: none !important;
}
.list-row .list-row-activity .modified, .list-row-head .list-row-activity .modified{
    display: none !important;
}
.list-row .list-row-activity .comment-count, .list-row-head .list-row-activity .comment-count{
    display: none !important;
}
.list-row .list-row-activity>span, .list-row-head .list-row-activity .span {
    display: none;
}
[data-theme=modern_ui_theme]{
    --primary: var(--main-color);
    --dt-primary-color: var(--main-color);
    --blue: var(--main-color)
}
[data-theme=modern_ui_theme].ce-header{
    text-align: right;
    background: white;
}
[data-theme=modern_ui_theme] .page-container{
    background-color: var(--widget-bg);
}

[data-theme=modern_ui_theme] .navbar .container{
    margin-right: 0px;
}
[data-theme=modern_ui_theme] #body .container{
    margin-left: 0px;
    
}
[data-theme=modern_ui_theme] .navbar .navbar-collapse{
    position: relative;
    
}
[data-theme=modern_ui_theme] .layout-side-section {
    display: none;
} 
[data-theme=modern_ui_theme] .sidebar-toggle-btn {
    display: none;
} 
[data-theme=modern_ui_theme] .control-input-wrapper .help-box {
    color: red !important;
}

/* Main */
[data-theme=modern_ui_theme] body {
	font-size: 14px;
	background-color: #dbdeffad;
}
[data-theme=modern_ui_theme]a {
    color: #666;
	font-weight: 500;
}
[data-theme=modern_ui_theme] .small, small {
    font-size: 95%;
    /* display: none !important; */
}

[data-theme=modern_ui_theme] .h6.uppercase, h6.uppercase {
    font-weight: 600;
	font-size: 11.5px;
}

/* Navigation Bar */
[data-theme=modern_ui_theme] .standard-sidebar-item.selected{
    background: linear-gradient(to left, var(--main-color), var(--widget-bg));
}
[data-theme=modern_ui_theme] .standard-sidebar-item.selected a{
    color: #fff;
}
[data-theme=modern_ui_theme] .standard-sidebar-item.selected a svg{
    fill: #fff;
}
[data-theme=modern_ui_theme] .standard-sidebar-item:hover{
    background-color: var(--second-color);
}
[data-theme=modern_ui_theme] .standard-sidebar-item:hover a,
[data-theme=modern_ui_theme] .standard-sidebar-item:hover svg{
    color: #fff;
    fill: #fff;
}



[data-theme=modern_ui_theme] .navbar, .grid-heading-row{
    /* background: linear-gradient(to right, var(--main-color), var(--second-color)) !important; */
    background: var(--second-color) !important;
    border: none !important;
    color: #fff;
   
}
[data-theme=modern_ui_theme] .navbar-nav>li>a{
	color:#fff !important;
}
[data-theme=modern_ui_theme] .navbar-nav>li>a:hover{
	color:#ddd !important;
}
[data-theme=modern_ui_theme] .navbar-brand:hover {
	color:#ddd !important;
}
[data-theme=modern_ui_theme] .navbar .navbar-brand {
    width: 200px;
}

/* Widgets */
[data-theme=modern_ui_theme] .widget {
    background-color:#c3cbf4;
	border: none;
    border-right: 5px solid var(--second-color) !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
[data-theme=modern_ui_theme] .widget:hover{
    
    border-right: 5px solid var(--main-color) !important;
	
}
[data-theme=modern_ui_theme].widget .widget-head .widget-label .widget-title:hover {
color: white;
}
[data-theme=modern_ui_theme] .widget.shortcut-widget-box .widget-head .widget-title i {
    color:white;
}
[data-theme=modern_ui_theme] .widget.shortcut-widget-box .widget-head .widget-title:hover span{
    color: #fff;
}
[data-theme=modern_ui_theme] .widget.shortcut-widget-box:hover span{
    color: #fff;
}
[data-theme=modern_ui_theme] .widget-body .link-item:hover ,.report-footer{
    color: #fff !important;
    background: var(--third-color);
}
[data-theme=modern_ui_theme] .page-form{
    background-color: var(--third-color);
}
[data-theme=modern_ui_theme] .widget.shortcut-widget-box {
    cursor: pointer;
    padding: 15px 30px;
    align-items: left;
    border: 1px solid var(--gray-300);
}
[data-theme=modern_ui_theme] .widget.shortcut-widget-box:hover {
    background: var(--second-color);
}
[data-theme=modern_ui_theme] .widget .widget-head .widget-title {
    font-size: 18px;
}
[data-theme=modern_ui_theme] .widget.links-widget-box {
    border: 1px solid var(--gray-300);
}

[data-theme=modern_ui_theme] .widget.links-widget-box .link-item {
    padding: 5px 30px;
    font-size: 18px;
}
[data-theme=modern_ui_theme].codex-editor .codex-editor__redactor .ce-block .ce-block__content .widget.spacer{
    display: none;
}
[data-theme=modern_ui_theme] .ce-header {
    background: var(--widget-bg);
    border-radius: 10px;
    padding: 10px 5px;
    padding-right: 5px;
    padding-right: 5px;
    text-align: center;
}

[data-theme=modern_ui_theme] .link-content:hover {
    color: #fff;
}
.layout-main-section.frappe-card {
    overflow: hidden;
    border-radius: var(--border-radius-md);
    box-shadow: var(--card-shadow);
    padding: 0px;
    background-color: var(--card-bg);
    box-shadow: 0px 0px 40px 0px #00000026 !important;
    border: 1px solid var(--border-color);
}

.layout-main-section .ce-block .ce-block__content .links-widget-box .widget-head{
    background-color: cornflowerblue;
    padding-right: 10px;
    border-radius: 6px;
    padding-top: 9px;
    padding-bottom: 9px;

}
.layout-main-section .ce-block .ce-block__content .links-widget-box .widget-head .ellipsis{
    color: white;
    font-size: 18px;
}
.widget-shortcut-widget-box:hover .widget-title .ellipsis {
    color: white; /* Change text color to white when hovering over the widget */
}
.widget .dashboard-widget-box .chart-actions .chart-menu {
    background-color: white;
}
/*list */
.frappe-card .standard-filter-section .checkbox .label-area {
    color: white !important;
}
/* Sidebar */
[data-theme=modern_ui_theme] .desk-sidebar .desk-sidebar-item {
    font-size: 13px;
    font-weight: 600 ;
	color: var(--widget-bg);
}
[data-theme=modern_ui_theme] .layout-side-section {
    font-size: 14px;
}
/* [data-theme=modern_ui_theme] .layout-main-section.frappe-card {
    border: 3px solid var(--third-color);
} */
[data-theme=modern_ui_theme] .uppercase {
    color:var(--third-color);
}

/* Dropdowns */
[data-theme=modern_ui_theme] .dropdown-menu>li>a {
    font-size: 13px;
	color:#666;
	font-weight: 500;
}
[data-theme=modern_ui_theme] .awesomplete > ul > li[aria-selected=true]{
    background-color: transparent;
}
/* [data-theme=modern_ui_theme] .awesomplete > ul > li:hover{
    background: linear-gradient(to right, var(--main-color), var(--widget-bg));
} */
[data-theme=modern_ui_theme] .awesomplete > ul > li:hover a{
    color: #fff;
}
/* Page Heading */
[data-theme=modern_ui_theme] .page-head {
    border-bottom: none !important;
    box-shadow: 0 1px 10px 1px rgba(115, 108, 203, 0.1);
}
[data-theme=modern_ui_theme] .page-title .title-text {
	color: var(--third-color);
	font-size: 22px;
}
.codex-editor .codex-editor__redactor .ce-block .ce-block__content .ce-header{
    text-align: right;
    background: white;
    
}
.codex-editor .codex-editor__redactor .ce-block .ce-block__content .ce-header .h4 {
    font-size: 22px !important;
    font-weight: 700 !important;
    letter-spacing: .01em;
    color: var(--third-color);
}
/* [data-theme=modern_ui_theme] .widget .widget-head .widget-title,
[data-theme=modern_ui_theme] .section-head,
[data-theme=modern_ui_theme] .comment-input-header {
    font-size: 14px;
	color: var(--third-color);
} */

/*Main Section */
[data-theme=modern_ui_theme] .list-row-head, .datatable {
    background-color: #f3f6f9;
    border-bottom: 1px solid #e4e4e4 !important;
}

/* Buttons */
[data-theme=modern_ui_theme] .btn-primary {
    background-color: var(--main-color);
    border-color: var(--main-color);
}
[data-theme=modern_ui_theme] .control-label, .grid-heading-row {
    font-size:13px;
}
[data-theme=modern_ui_theme] .btn-default:hover,
[data-theme=modern_ui_theme] .btn-default:focus, .btn-default.focus,
[data-theme=modern_ui_theme] .btn-default:active, .btn-default.active,
[data-theme=modern_ui_theme] .open > .dropdown-toggle.btn-default {
    background-color: #fff !important;
    color: #121212 !important;
}
[data-theme=modern_ui_theme] .btn-default:hover svg{
    stroke: #fff !important;
    fill: #fff !important;
    color: #fff !important;
}
[data-theme=modern_ui_theme] .btn-primary:hover,
[data-theme=modern_ui_theme] .btn-primary:focus, .btn-primary.focus,
[data-theme=modern_ui_theme] .btn-primary:active, .btn-primary.active,
[data-theme=modern_ui_theme] .open > .dropdown-toggle.btn-primary {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}
[data-theme=modern_ui_theme] .btn-secondary,
[data-theme=modern_ui_theme] .btn-secondary:focus, .btn-secondary.focus,
[data-theme=modern_ui_theme] .btn-secondary:active, .btn-secondary.active,
[data-theme=modern_ui_theme] .open > .dropdown-toggle.btn-secondary {
    background-color: var(--main-color);
    color: #fff;
}

[data-theme=modern_ui_theme] .btn-secondary:hover{
    background-color: var(--main-color);
    color: #fff;
}
[data-theme=modern_ui_theme] .indicator-right.blue:after, .indicator.blue:before {
    background: var(--third-color);
}

/*Other Components*/
[data-theme=modern_ui_theme] input[type=checkbox]:checked:before {
    color: var(--third-color);
}
[data-theme=modern_ui_theme] input[type=checkbox]:checked{
    background: var(--main-color);
}
[data-theme=modern_ui_theme] .frappe-control .ql-editor:not(.read-mode){
    background: var(--widget-bg);
}
/* [data-theme=modern_ui_theme] .input-with-feedback,
[data-theme=modern_ui_theme] .like-disabled-input{
    background-color: #e8eafcad;
} */

[data-theme=modern_ui_theme] .document-link-badge span{
    background-color: var(--main-color) !important;
    color: #fff;
}

[data-theme=modern_ui_theme] .document-link-badge{
    background-color: var(--widget-bg)!important;
}
[data-theme=modern_ui_theme] .document-link button{
    background: var(--widget-bg)  !important;
    color: #fff !important;
}

[data-theme=modern_ui_theme] .document-link button svg{
    fill: white;
    stroke: white;
}
/* [data-theme=modern_ui_theme] .indicator-pill.gray,
[data-theme=modern_ui_theme] .indicator-pill-right.gray,
[data-theme=modern_ui_theme] .indicator-pill-round.gray {
    background: var(--third-color);
    color: #fff;
} */
[data-theme=modern_ui_theme] .indicator-pill.green,
[data-theme=modern_ui_theme] .indicator-pill-right.green,
[data-theme=modern_ui_theme] .indicator-pill-round.green {
    color: var(--main-color);
}

[data-theme=modern_ui_theme] .notifications-icon svg{
    stroke: #fff;
    fill: #fff;
}

[data-theme=modern_ui_theme] #page-Workspaces .page-head{
    background: var(--widget-bg);
}

[data-theme=modern_ui_theme] .form-tabs-list .form-tabs .nav-item .nav-link {
    padding: 5px 10px;
    margin: 5px;
}
[data-theme=modern_ui_theme] .nav-link {
    padding: 5px 10px; /* Adjust top/bottom and left/right padding */
    height: auto; /* Ensure the height is automatic based on content */
    border-radius: 5px;
}
[data-theme=modern_ui_theme] .nav-link:hover {
    background-color:var(--main-color) ;
    color: #fff !important;
}
[data-theme=modern_ui_theme] .nav-link.active {
    font-weight: 500;
    border-bottom: 1px solid var(--primary);
    color: #fff !important;
    background-color: var(--second-color);
    border-radius: 6px;
}

[data-theme=modern_ui_theme] [data-page-route=Workspaces] .sidebar-toggle-btn,
[data-theme=modern_ui_theme] [data-page-route=Workspaces] .layout-side-section {
    display: none;
}


[data-theme]:not([data-theme="modern_ui_theme"]) .sidebar {
    /* Your styles here */
    display: none;
}
.btn .icon-btn .btn-default, div#driver-popover-item .driver-popover-footer button .icon-btn .btn-default, .btn.icon-btn .btn-secondary, div#driver-popover-item .driver-popover-footer button .icon-btn .btn-secondary, div#driver-popover-item .driver-popover-footer button .icon-btn .driver-prev-btn{
    background-color: #3b9bfc !important;
}
.container .navbar-nav .dropdown-help span {
    color: #fff !important;  /* Set the text color of list items */
}
.container .navbar-nav .dropdown-notifications .notifications-seen .es-icon {
      /* Set the text color of list items */
    fill: #f8f5f5!important;
    stroke: #efe5e5e3!important;
}

.widget.spacer {
    display: none !important;
}
.form-tabs-list{
    background-color: #e2e2e2;
}
[data-theme=modern_ui_theme] {
   --right-arrow-svg : url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M1.25 7.5L4.75 4L1.25 0.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}
/* [data-theme=modern_ui_theme] .layout-main-section.frappe-card {
   
    width: calc(100vw - 20%) !important;
} */
/* CSS media queries */

@media only screen and (min-width: 320px) and (max-width: 768px) {
    [data-theme=modern_ui_theme] .navbar {
        right: 0 !important;
        width: 100% !important;
    }
    .container-lg, .container-md, .container-sm, .container {
        max-width: 767px!important;
    }
    .navbar-home img {
        max-height: 55px;
        width: auto;
        position: absolute;
        top: -7px;
    }
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        /* left: 100px; */
    }
    .dv-user-info {
        
        display: none;
    }
    .justify-content-end {
       
        display: none; 
    }
}
@media only screen and (min-width: 769px) and (max-width: 823px){
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        /* position: relative; */
        right: 147px;
    }
    .container-md, .container-sm, .container {
        max-width: 622px;
    }
}
@media only screen and  (min-width: 823px) and (max-width: 965px){
    [data-theme=modern_ui_theme] .layout-main-section {
        max-width: 650px;
       
    }
    [data-theme=modern_ui_theme] .container {
        position: relative;
        right: 150px;
    }
    [data-theme=modern_ui_theme] .navbar .container {
        margin-right: -144px;
    }
}
@media only screen and (min-width:966px) and (max-width: 1051px){
   
    .container-md, .container-sm, .container {
        max-width: 755px;
    }
  
}
@media (min-width: 992px)and (max-width: 1200px) {

   
    .container-lg, .container-md, .container-sm, .container {
        max-width: 761px!important;
    }
    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: relative;
        top: 30px;
        right: 20px;
    }
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        right: 200px;
    }
}

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        width: calc(100vw - 17%) !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    
    /* .navbar-home img {
        max-height: 112px;
        width: auto;
        position: relative;
        top: 32px;
        left: 20px;
    } */
    [data-theme=modern_ui_theme] .navbar {
        right: 0 !important;
        width: 100% !important;
    }
    [data-theme=modern_ui_theme] #navbar-breadcrumbs {
        position: relative;
        right: -100px;
    }
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        
    }
    
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .container-lg, .container-md, .container-sm, .container {
        width: 83% !important;
    }
    [data-theme=modern_ui_theme] .navbar {
        right: 0 !important;
        width: 100% !important;
    }
    [data-theme=modern_ui_theme] #navbar-breadcrumbs,
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        right: 250px !important;
    }
    
.navbar-home img {
    max-height: 115px;
    width: auto;
    position: absolute;
    top: 1px;
    right: 48px;
}
}

@media only screen and (min-width: 1601px) and (max-width: 1900px) {
    .container-lg, .container-md, .container-sm, .container {
        width: calc(100vw - 10%) !important;
    }
    [data-theme=modern_ui_theme] .navbar {
        right: -10px !important;
        width: 100%;
    }
    [data-theme=modern_ui_theme] #navbar-breadcrumbs,
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        right: 250px !important;
    }
    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: absolute;
        top: 0;
        right: 25px;
    }
}

@media (min-width: 1600px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1650px !important;
        margin-right: 250px !important;
    }
    [data-theme=modern_ui_theme] .page-wrapper {
        width: 1700px !important;
    }
    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: absolute;
        top: 0;
        right: 25px;
    }
}

@media (min-width: 2500px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 400vh !important;
        margin-right: 250px !important;
    }
    [data-theme=modern_ui_theme] .page-wrapper {
        width: 2234px !important;
    }
    [data-theme=modern_ui_theme] .navbar .navbar-collapse {
        position: relative;
        right: 230px;
    }
    [data-theme=modern_ui_theme] .navbar, .grid-heading-row {
        background: var(--second-color) !important;
        border: none !important;
        color: #fff;
        right: 0 !important;
        width: 100% !important;
    }
    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: absolute;
        top: 0;
        right: 25px;
    }
}
